@font-face {
  font-family: 'TT Trailers';
  font-style: normal;
  font-weight: normal;
  src: local('TT Trailers'), url('./fonts/TTTrailers-Bold.otf');
}

@font-face {
  font-family: 'Nunito Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Regular'), url('./fonts/Nunito-Regular.ttf') format('woff');
}

:root {
  --primary: #9e29d0;
  --secondary: #c36de7;
  --bg: #4e245a;
  --textColor: rgb(255, 255, 255);
}

/* fazal csss  */
.dashboard .custom-header {
  position: unset !important;
  z-index: 0 !important;
  margin-left: 0px !important;
  background-color: #c36de7 !important;
  box-shadow: none !important;
}

.dashboard main.MuiBox-root.css-1w1gdci {
  background-color: #c36de7 !important;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  /* background-color: #c36de7 !important; */
}

.dashboard .css-1x9x74r-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #c36de7 !important;
}

.dashboard .MuiDrawer-root.MuiDrawer-docked.css-1x9x74r-MuiDrawer-docked {
  border-right: 1px solid #202e4e;
}

.dashboard .logo.d-flex {
  background-color: #4e245a !important;
  justify-content: center;
}

.dashboard .logo.d-flex a > img {
  background-color: #4e245a !important;
}

.dashboard .active-list-main .text-grey {
  color: #9e29d0 !important;
}

.dashboard .active-list-main {
  background-color: transparent !important;
  border: none !important;
  color: #9e29d0 !important;
  box-shadow: none !important;
}

.dashboard .list-main {
  border: none !important;
  color: #949494 !important;
}

.dashboard .MuiListItemIcon-root {
  min-width: 40px !important;
}

.dashboard main header .main-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashboard main header .main-nav .login-box {
  width: 100% !important;
  flex-basis: 100%;
}

.dashboard .content .heading h3,
.content .heading h6,
.content .heading h5 {
  color: white;
}

.dashboard .dashboard-box {
  background-color: #4e245a !important;
  border-radius: 20px !important;
  border: none !important;
  padding: 1rem 1rem !important;
}

.dashboard .dashboard-box.first-box {
  background-color: #9e29d0 !important;
  border-radius: 20px !important;
  border: none !important;
  padding: 1rem 1rem !important;
}

.dashboard .box-layer-1 {
  background-color: #4e245a;
  padding: 1rem 1rem;
}

.dashboard .box-layer-2 {
  background-color: #1f193c;
  padding: 1rem 1rem;
  border-radius: 20px;
}

.dashboard .box-layer-1 .color-primary {
  color: white !important;
}

.sell-box-layer-1 {
  background-color: var(--textColor);
  padding: 3px;
  border-radius: 20px;
}

.sell-box-layer-2 {
  background-color: #1f193c;
  padding: 1rem 1rem;
  border-radius: 20px;
}

.sell-box {
  background-color: #4e245a !important;
  padding: 1rem 1rem;
  border-radius: 20px !important;
  position: relative;
}

.sell-box .nft-img {
  width: 100%;
}

.sell-box .nft-profile {
  position: absolute;
  right: 5px;
  bottom: -20px;
  width: 49px;
  height: 49px;
}

.sell-box-body {
  margin-top: 2rem;
}

.sell-box-body h3 {
  color: white;
  margin-bottom: 4px;
  font-size: 21px;
}

.sell-box-body h4 {
  color: white;
  font-size: 18px;
}

.sell-flex-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sell-flex-price p {
  color: #9e29d0;
  font-size: 17px;
}

.dashboard .nft-footer {
  text-align: center;
  margin-top: 0.5rem;
}

.dashboard .custom-mt-1 {
  margin-top: 1rem !important;
}

.dashboard .main-nav {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dashboard .main-nav .nav-dropdown {
  margin-left: 7px !important;
}

.dashboard .p-relative {
  position: relative !important;
}

/* // certificate css  */
.portion-address {
  margin-top: 1rem;
}

.portion-address p {
  margin-bottom: 0;
  font-size: 18px;
  color: #9e29d0;
}

.portion-address span {
  font-size: 14px;
  color: #9e29d0;
}

.position-relative {
  position: relative;
}

.primary-btn {
  background: #9e29d0;
  background: var(--primary);
  box-shadow: 4px 4px 8px rgb(0 0 0 / 30%);
  border-color: #9e29d0;
  border-color: var(--primary);
  color: #fff;
  cursor: pointer;
}

.custom-btn {
  border-radius: 5px;
  border: 1px solid;
  padding: 0.3rem 1rem;
  font-size: 15px;
}

.nft-tag {
  position: absolute;
  z-index: 1;
  background: #9e29d0;
  top: 0;
  right: 0;
  padding: 0.2rem 0.5rem;
  color: white;
}

.qr-code-section img {
  width: 200px;
}

.certificate-modal .MuiDialogContent-root {
  padding: 0.7vh 0.5vh;
  padding-bottom: 2.5vh;
}

.certificate-modal .MuiPaper-root.MuiPaper-elevation {
  background-color: transparent;
  border-radius: 56px;
  max-height: unset;
  
}

.profile-modal 
{
  border-radius: 56px !important;
  background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.4) -8.95%, rgba(255, 255, 255, 0.01) 114%) !important;
  backdrop-filter: blur(50px)  !important; 

}
.certificate-modal .blur-bg {
  border: 5px solid #8264e2;
  border-radius: 56px !important;
  position: relative;
  background: linear-gradient(111.49deg, rgba(255, 255, 255, 0.4) -8.95%, rgba(255, 255, 255, 0.01) 114%);
  backdrop-filter: blur(50px);
}

.certificate-modal .profile-tag-box
{
  text-align: center;
  background: #6478e2;
  border-radius: 44px;
  padding-bottom: 0.1vh;
  text-transform: uppercase;
}
.certificate-modal .tag-box {
  text-align: center;
  background: #6478e2;
  border-radius: 44px;
  padding-bottom: 0.5vh;
  text-transform: uppercase;
}
.certificate-modal .profile-tag-box h1 {
 
  color: white;
    font-size: 6.5vh;
    font-family: 'TT Trailers';
    line-height: 6vh;
    margin-bottom: 0px;
}

.certificate-modal .tag-box h1 {
  color: white;
  font-size: 7.5vh;
  font-family: 'TT Trailers';
  line-height: 8vh;
  margin-bottom: 0px;
}

.certificate-modal .tag-box h3 {
  color: white;
  font-size: 2.2vh;
  font-family: 'Nunito Regular';
  text-transform: uppercase;
  margin: 0.5vh 0px 0px 0px;
}

.certificate-modal .title-box {
  background-color: #070e1e;
  border-radius: 44px;
  padding: 6px 0px 10px 0px;
}

.certificate-modal .profile-title-box {
  background-color: #8264E2;
  border-radius: 44px;
  padding: 6px 0px 6px 0px;
}

.certificate-modal .title-box h3 {
  font-size: 2vh;
  margin: 0px;
}


.certificate-modal .MuiDialogTitle-root {
  background-color: #9e29d0 !important;
  text-align: center;
  color: white;
}

.building-img {
  display: block;
  margin: 1.5vh auto;
  width: 35vh;
  height: 35vh;
}

.certificate-modal .building-detail {
  text-align: center;
  background: rgba(7, 14, 30, 0.37);
}
.profile-detail
{
  text-align: center;
  background: rgba(255, 255, 255);
  
}
 .item-profile .title-2
 {
  font-family: 'Nunito Regular';
  font-size: 14px;
  text-align: left;
  color: #7D7D7D;
  margin-top: 0.8vh;
  margin-left: 1vh;
  margin-bottom: 0.8vh;
 }
 .item-profile .title-1 
 {
  font-family: 'Nunito Regular';
  font-size: 14px;
  color: #7D7D7D;
  text-align: center;
  margin-top: 0.8vh;
  margin-bottom: 0px;
  text-transform: uppercase;
 }
 .twele-col
 {
  border-bottom: 1px solid rgba(7, 14, 30, 0.17);

 }
 .six-col
 {
  border-right:  1px solid rgba(7, 14, 30, 0.17);

 }


.certificate-modal .building-detail .title-1 {
  font-family: 'Nunito Regular';
  font-size: 14px;
  color: #e6e4e2;
  text-align: center;
  margin-top: 0.8vh;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.certificate-modal .building-detail .title-2 {
  font-family: 'Nunito Regular';
  font-size: 14px;
  text-align: left;
  color: #e6e4e2;
  margin-top: 0.8vh;
  margin-left: 1vh;
  margin-bottom: 0.8vh;

}


.certificate-modal .building-detail .value {
  font-family: 'TT Trailers';
  text-align: center;
  font-size: 6.5vh;
  color: white;
  margin-top: 0.8vh;
  margin-bottom: 0.8vh;
  line-height: 5vh;
  text-transform: uppercase;
}

.certificate-modal .button-box {
  text-align: center;
  margin-top: 2vh;
  /* min-width: 55vh; */
}

.certificate-modal .primary-btn {
  background: #8264e2;
  border: none;
  border-radius: 55px;
  font-family: 'TT Trailers';
  font-size: 5vh;
  letter-spacing: 2px;
  box-shadow: 1px 3px 1px #070e1e;
  color: white;
  cursor: pointer;
  margin-left: 1vh;
  margin-right: 1vh;
  width: 30vh;
  text-transform: uppercase;
  padding: 0.5vh;
  line-height: 6.3vh;
}

.certificate-modal .primary-btn > span {
  text-align: left;
  /* margin: 21px 16px 21px 60px; */
}

.certificate-modal .custom-btn {
  background: white;
  border: none;
  border-radius: 55px;
  font-family: 'TT Trailers';
  font-size: 5vh;
  letter-spacing: 1px;
  box-shadow: 1px 3px 1px #070e1e;
  color: #070e1e;
  cursor: pointer;
  margin-left: 1vh;
  margin-right: 1vh;
  margin-bottom: 1vh;
  width: 30vh;
  text-transform: uppercase;
  padding: 0.5vh;
  line-height: 6.3vh;
}

.MuiInput-input,
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #ffffff !important;
  border-color: white !important;
}

.css-89bi5l-MuiInputBase-root-MuiInput-root,
.css-h7l260-MuiFormControl-root-MuiTextField-root {
  border-color: white !important;
}

.left-sidebar {
  position: fixed;
  z-index: 999;
  width: 250px;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #1f193b;
  opacity: 1;
  padding: 1rem 1rem;
  overflow: auto;
  transition: all ease 0.5s;
}

.left-sidebar header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

a:hover {
  color: white;
}

.white-btn {
  color: #5c5c5c;
  background: #ececec;
  border-color: #ececec;
}

.left-sidebar .body-section {
  padding: 0px 0px;
  margin-top: 1rem;
}

.left-sidebar .body-section label {
  color: #9e29d0;
}

.left-sidebar .body-section .form-control {
  background-color: #c36de7 !important;
  border: none !important;
  color: #9e29d0 !important;
  white-space: wrap;
}

.left-sidebar .body-section .form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-footer-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.danger-btn {
  background-color: #ffbfbf;
  border-color: #ffbfbf;
  color: #df4545;
}

.danger-btn-2 {
  background-color: #df4545;
  border-color: #df4545;
  color: white;
}

.light-primary-btn {
  background-color: #dfd4ff;
  border-color: #dfd4ff;
  color: #8364e2;
}

.table-section tr {
  margin-bottom: 1rem !important;
}

.table-section td,
.table-section th {
  border: none !important;
  font-size: 16px;
  padding: 0.5rem 0.5rem;
}

.table-section td {
  background-color: #1f193b;
  color: #9e29d0;
}

.table-section th {
  color: #949494;
}

.user-action-dropdown-btn {
  border: none !important;
  padding: 0 !important;
  color: #9e29d0 !important;
  background-color: unset !important;
}

.user-action-dropdown-btn:hover {
  background-color: unset !important;
}

.user-action-dropdown-btn:focus {
  box-shadow: none !important;
}

.user-action-dropdown-btn::after,
.user-action-dropdown-btn::before {
  display: none !important;
}

.user-action-dropdown .dropdown-menu {
  width: 300px;
  background-color: #1f193b !important;
  padding: 1rem 1rem;
  box-shadow: 0px 0px 20px rgba(131, 100, 226, 0.3);
}

.user-action-dropdown .profile-header {
  text-align: center;
}

.user-action-dropdown .profile-header img {
  width: 93px;
  height: 93px;
  border-radius: 50%;
}

.user-action-dropdown .profile-header p {
  color: #9e29d0;
  margin-top: 1rem;
}

.user-action-dropdown .dropdown-body ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.user-action-dropdown .dropdown-body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.user-action-dropdown .dropdown-body ul li p {
  margin: 0;
  color: #9e29d0;
  font-size: 13px;
}

.user-action-dropdown .dropdown-body ul li span {
  color: #949494;
  font-size: 13px;
}

.user-action-footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dark-danger-btn {
  background-color: #df4545;
  border: 1px solid #df4545;
  color: white;
}

.gray-btn {
  background-color: #565656;
  border: 1px solid #565656;
  color: white;
}

input[type='checkbox'] {
  accent-color: #9e29d0;
}

.pointer {
  cursor: pointer;
}

.left-sidebar .profile-image-section {
  margin-top: 1rem;
}

.profile-image-section img {
  width: 102px;
  height: 102px;
  border-radius: 50%;
}

.table-head-btn {
  display: flex;
  align-items: center;
}

.table-head-btn p {
  color: #949494;
  display: flex;
  margin: 0;
}

.table-head-btn p span:not(:first-child) {
  margin-left: 10px;
}

.table-head-btn .btns {
  margin-left: 0.5rem;
}

.table-head-btn .action-btn {
  background: #1f193b;
  border-radius: 5px;
  color: #9e29d0;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
}

.data-limit-dropdown .data-limit {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid;
  padding: 0;
  margin-left: 10px;
  color: #949494;
}

.data-limit-dropdown .dropdown-menu {
  background-color: #4e245a;
  min-width: 60px;
}

.data-limit-dropdown .dropdown-menu .dropdown-item {
  color: white;
}

.data-limit-dropdown .dropdown-menu .dropdown-item:hover,
.data-limit-dropdown .dropdown-menu .dropdown-item:focus,
.data-limit-dropdown .dropdown-menu .dropdown-item:active {
  background-color: #9e29d0 !important;
}

.data-limit-dropdown .data-limit::before,
.data-limit-dropdown .data-limit::after {
  display: none !important;
}

.data-limit-dropdown .data-limit:hover,
.data-limit-dropdown .data-limit:focus,
.data-limit-dropdown .data-limit:active {
  background-color: transparent !important;
  box-shadow: none;
}

.table-footer-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;
}

.table-footer-navigation p {
  margin-bottom: 0;
  color: #949494;
  margin-left: 0.5rem;
}

.table-footer-navigation .btn-move {
  background: #9e29d0;
  border: none;
  padding: 0.2rem 0.5rem;
  font-size: 20px;
  color: white;
  margin-left: 0.8rem;
}

.table-footer-navigation .btn-move:disabled {
  background-color: #c5b2ff !important;
}

.paginate-btn {
  background: #1f193b;
  border: none;
  padding: 0.4rem 1rem;
  color: #949494;
}

.send-box {
  border-radius: 5px;
  width: 28px;
  height: 28px;
  margin: auto;
}

.send-box img {
  width: 95%;
}

.red-bg {
  background: red;
}

.primary-bg {
  background: #8364e2;
}

.certificate .custom-nav-tabs 
{
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin: auto;
}

.certificate .custom-nav-tabs li .img-div {
  border: 1px solid #9e29d0;
  padding: 1rem;
  background-color: transparent;
  border-radius: 2rem;
  text-align: center;
}

.certificate .custom-nav-tabs li img {
  width: 30px;
  height: 30px;
}

.certificate .custom-nav-tabs li {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.certificate .custom-nav-tabs li span {
  /* color: #9e29d0; */
  color: white;
  margin-top: 0.5rem;
  font-size: 17px;
  font-weight: 500;
  /* background-color: #c36de7 !important; */
}

.certificate .custom-nav-tabs li span :hover {
  transition: background-color 1s;
  background-color: #9e29d0;
}

.certificate .custom-nav-tabs li :hover {
  transition: background-color 1s;
  background-color: white;
  /* color: black; */
}

.certificate-body .nft-circle {
  background-color: rgba(255, 255, 255, 0);
  /* margin: auto; */
  text-align: center;
  height: 300px;
  width: 330px;
  border: 5px solid #9e29d0;
  border-radius: 50%;
  position: relative;
}

.certificate-body .nft-circle .nft-img {
  height: 290px;
  width: 315px;
  /* margin-bottom: 0.5rem; */
  cursor: pointer;
  border-radius: 50%;
}

.certificate-body .nft-circle .tag {
  position: absolute;
  background-color: #9e29d0;
  width: 200px;
  left: 15%;
  bottom: 0%;
  transform: translate(10px, 50%);
  border-radius: 10px;
  clip-path: polygon(100% 1%, 100% 50%, 100% 100%, 0% 100%, 5% 49%, 0% 0%);
}

.certificate-body .nft-circle .tag {
  padding: 0 1rem;
}

.certificate-body .nft-circle .tag span {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.certificate-body .nft-circle .rating img:nth-child(2) {
  height: 40px;
  margin-bottom: 4px;
}

.nft-name {
  margin-top: 1rem;
}

.nft-name h5 {
  /* color: #9e29d0; */
  color: white;
  text-transform: uppercase;
}

.nft-name h4 {
  /* color: #9e29d0; */
  color: white;
  text-transform: uppercase;
}

.primary-input {
  background-color: #1f193c !important;
  border: none !important;
  color: white;
  margin-bottom: 0px !important;
  font-size: 19px;
  text-transform: uppercase;
}

.digital-building-detail {
  justify-content: space-between !important;
}

.digital-building-detail li {
  max-width: 33.33% !important;
  margin-top: 1rem;
}

.resell-main {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1f193c;
  width: 100%;
  height: 0px;
  overflow: hidden;
}

body .resell-show {
  height: 100% !important;
  overflow: auto !important;
  padding: 2rem 3rem;
}

.primary-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

/* Track */
.primary-scroll::-webkit-scrollbar-track {
  background: #131129;
}

/* Handle */
.primary-scroll::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

/* Handle on hover */
.primary-scroll::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

body .custom-primary-group {
  display: flex;
}

body .custom-primary-group .form-control {
  background-color: #131129;
  border: 1px solid #131129;
  border-radius: none !important;
  color: white !important;
}

.custom-primary-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #9e29d0;
}

.row-col-text {
  display: -webkit-flex;
  align-items: center;
}

.row-col-text .custom-primary-group .form-label {
  background-color: #9e29d0;
  color: white !important;
  width: 100%;
  /* padding: 1rem 0; */
  padding: 0.5rem 0;
  text-align: center;
  border: 1px solid white;
  margin: 0 !important;
  /* font-size: 18px; */
  font-size: 14px;
}

.row-col-text .custom-primary-group .form-control {
  /* background-color: #ded4f9; */
  /* color: #9e29d0 !important; */
  /* font-size: 18px; */
  font-size: 14px;
  margin: 0 !important;
  border-radius: 0px !important;
  /* padding: 1rem; */
  padding: 0.3rem 0 !important;
  border: 1px solid white;
  text-align: center;
}

.row-col-text .form-group {
  padding: 0px !important;
}

.custom-primary-group .form-label {
  /* color: #9e29d0; */
  color: white;
  font-size: 18px;
}

.main-asset {
  padding: 1rem 0;
}

.main-asset .nft-img {
  width: 200px;
  height: 200px;
}

.asset-profile {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1f193c;
  width: 100%;
  height: 0px;
  overflow: hidden;
}

.assets {

  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #c36de7;
  width: 100%;
  height: 0px;
  overflow: hidden;
}

.street-view-modal {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1f193c;
  width: 100%;
  height: 0px;
  overflow: hidden;
  padding: 0px !important;
}

.main-asset:not(:last-child) {
  /* border-bottom: 2px solid #31275E; */
}

.main-asset {
  /* width: 100%;
  overflow: auto; */
}

.custom-form-main {
  display: -webkit-box !important;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.custom-form-main .form-group {
  /* max-width: 33.3%; */
  padding: 0rem 0.5rem;
}

.group-flex-btn {
  display: flex;
}

.group-flex-btn .custom-btn {
  margin-left: 0.5rem;
}

.street-header {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.street-header svg {
  color: #9e29d0;
  cursor: pointer;
}

.heading-btn {
  display: flex;
  align-items: center;
}

.heading-btn h3 {
  margin-bottom: 0;
  color: white;
  margin-right: 3rem;
}

.property-inner-heading {
  margin-top: 1rem;
}

.property-inner-heading h4 {
  color: white;
  font-weight: 500;
}

.property-inner-heading .coin-detail {
  display: flex;
  align-items: center;
}

.property-inner-heading .coin-detail img {
  width: 30px;
}

.property-inner-heading .coin-detail span {
  color: white;
  margin-left: 0.5rem;
}

.resell-main .sell-box-layer-1 {
  margin-top: 1rem;
}

.dialog-close-header {
  display: flex;
  justify-content: flex-end;
}

.dialog-close-header svg {
  
  /* color: #9e29d0; */
  color: white;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.nft-profile.circle-div {
  background-color: #9e29d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.nft-profile.circle-div span {
  color: white;
}

.preview-image-modal .MuiDialogContent-root {
  background-color: #4e245a;
}

.preview-image-modal .dialog-close-header {
  margin-bottom: 0px !important;
}

.preview-image-inner {
  width: 400px;
  height: 400px;
}

.preview-image-main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-dialog-header {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 5px;
}

.preview-dialog-header svg {
  /* color: #9e29d0; */
  color: white;
  cursor: pointer;
  margin: 0 0.5rem;
}

.dashboard .balance-box-main .MuiGrid-item {
  background-color: #9e29d0;
  color: white !important;
  padding: 1rem 0;
  text-align: center;
  border: 1px solid white;
  margin: 0 !important;
  font-size: 18px;
  max-height: 70px !important;
}

.dashboard .balance-box-main .MuiGrid-item h6 {
  margin-bottom: 0;
}

.dashboard .balance-box-main .MuiGrid-item:nth-child(2) {
  background-color: #131129 !important;
}

.custom-chain-dropdown {
  display: inline-block;
  margin-left: 1rem;
}

.custom-chain-btn-flex {
  display: flex;
  align-items: flex-start;
}

.custom-chain-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-chain-dropdown .dropdown-menu {
  /* background-color: ; */
}

.custom-chain-dropdown .dropdown-toggle {
  color: #fff !important;
  background: #8364e2;
  margin-left: 13px;
  border-radius: 6px;
  font-weight: 800;
  text-decoration: none;
  padding: 9px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.custom-chain-dropdown .dropdown-toggle::before {
  display: none !important;
}

.custom-chain-dropdown .dropdown-toggle:hover {
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 50%);
  transition: all 0.3s ease;
}

.deposit-modal h2 {
  border-bottom: 1px solid #9e29d0 !important;
  padding: 0.9rem 1rem !important;
  margin-bottom: 1rem;
  font-size: 18px;
}

.deposit-modal .MuiDialogContent-root {
  padding: 1rem 1rem;
}

.deposit-ul-detail {
  padding: 0;
  list-style: none;
  background-color: #f1efef;
  padding: 0.1rem 0.3rem;
}

.deposit-ul-detail li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.deposit-ul-detail li span {
  color: gray;
  font-size: 13px;
}

.deposit-ul-detail li p {
  margin-bottom: 0;
  font-size: 14px;
}

.flex-start {
  justify-content: flex-start !important;
}

/* end fazal css  */

/* @media screen and (max-width: 550px) 
{

  .MuiBox-root .css-sybov2
  {
    margin-top: 0px;
  }
  .css-sybov2
  {
    
    margin-top: 0px;
  }
  
} */
